<template>
  <div class="statistics-container">
    <div class="statistics-header">
      <div class="row-header">
        <h2 class="page-subtitle">{{ $t('portfolio.statistics') }}</h2>
        <el-select
          filterable
          v-model="form.period"
          :effect="darkmode ? 'dark' : ''"
          class="select"
        >
          <el-option
            v-for="item in getLists.report_period"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span>
              {{ item.label }}
            </span>
          </el-option>
        </el-select>

        <el-select
          filterable
          v-model="form.currency"
          :effect="darkmode ? 'dark' : ''"
          disabled
          class="select"
        >
          <el-option
            v-for="item in getLists.currency"
            :key="item.currency"
            :label="item.currency"
            :value="item.currency"
          >
            <span>
              {{ item.currency }}
            </span>
          </el-option>
        </el-select>

        <el-popover placement="bottom" :width="350" trigger="click">
          <template #reference>
            <div v-if="returnGlobalDelay() > 0" class="portfolio-stats-history-delay hidden-tel">
              <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              <span>d</span>

              <p>
                {{ $t('product_page.price_history.delay') }}
                {{ returnGlobalDelay() }}
                {{ $t('product_page.price_history.delay_2') }}
              </p>
            </div>
          </template>
          <div class="subscriptions-list">
            <div class="spirits-types-list">
              <div class="spirits-types-list-1">
                <div class="rhum-ron-rum">
                  <img
                    src="@/assets/svg/v2/categories/rhum_ron_rum.svg"
                    alt="Rhum, Ron & Rum"
                  />
                  <p>Rhums
                    <span v-if="!returnDelay(14).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(14).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(14).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Rhum, Rum, Ron, Cachaca"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
                <div class="whiskey-whisky">
                  <img
                    src="@/assets/svg/v2/categories/whiskey_whisky.svg"
                    alt="Whiskey & Whisky"
                  />
                  <p>Whisky
                    <span v-if="!returnDelay(24).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(24).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(24).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Whisky, Whiskey"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
                <div class="bourbon-rye">
                  <img
                    src="@/assets/svg/v2/categories/bourbon_rye.svg"
                    alt="Bourbon & Rye"
                  />
                  <p>American Whisky
                    <span v-if="!returnDelay(3).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(3).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(3).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Bourbon, Rye, American Whisky"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
              </div>
              <div class="spirits-types-list-2">
                <div class="tequila">
                  <img
                    src="@/assets/svg/v2/categories/tequila.svg"
                    alt="Tequila"
                  />
                  <p>Eau de vie
                    <span v-if="!returnDelay(1).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(1).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(1).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Cognac, Armagnac, Brandy, Grappa, Marc, Kirsh, Schnaps, Eau de vie, Pisco, Calvados, Rakia"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
                <div class="cognac">
                  <img src="@/assets/svg/v2/categories/cognac.svg" alt="Cognac" />
                  <p>Agave, Distillate & Various
                    <span v-if="!returnDelay(10).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(10).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(10).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Vodka, Gin, Genever, Sake, Shochu, Soju, Baiju, Mezcal, Tequila, Sotol, Bacanora, Various"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
                <div class="liquors">
                  <img
                    src="@/assets/svg/v2/categories/liquors.svg"
                    alt="Liquors"
                  />
                  <p>Liqueur & Aperitif
                    <span v-if="!returnDelay(9).isSub" class="with-delay">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(9).value }}
                    </span>
                    <span v-else class="already-sub">
                      <span style="color: white;"> - </span>
                      {{ returnDelay(9).value }}
                    </span>
                  </p>
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Chartreuse, Liqueur, Absinthe, Ouzo, Crème"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>

    <el-popover placement="bottom" :width="330" trigger="click">
      <template #reference>
        <div class="portfolio-stats-history-delay hidden-pc">
          <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
          <span>d</span>

          <p>
            {{ $t('product_page.price_history.delay') }}
            {{ returnGlobalDelay() }}
            {{ $t('product_page.price_history.delay_2') }}
          </p>
        </div>
      </template>
      <div class="subscriptions-list">
        <div class="spirits-types-list">
          <div class="spirits-types-list-1">
            <div class="rhum-ron-rum">
              <img
                src="@/assets/svg/v2/categories/rhum_ron_rum.svg"
                alt="Rhum, Ron & Rum"
              />
              <p>Rhums
                <span v-if="!returnDelay(14).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(14).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(14).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Rhum, Rum, Ron, Cachaca"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </el-tooltip>
            </div>
            <div class="whiskey-whisky">
              <img
                src="@/assets/svg/v2/categories/whiskey_whisky.svg"
                alt="Whiskey & Whisky"
              />
              <p>Whisky
                <span v-if="!returnDelay(24).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(24).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(24).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Whisky, Whiskey"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </el-tooltip>
            </div>
            <div class="bourbon-rye">
              <img
                src="@/assets/svg/v2/categories/bourbon_rye.svg"
                alt="Bourbon & Rye"
              />
              <p>American Whisky
                <span v-if="!returnDelay(3).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(3).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(3).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Bourbon, Rye, American Whisky"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </el-tooltip>
            </div>
          </div>
          <div class="spirits-types-list-2">
            <div class="tequila">
              <img
                src="@/assets/svg/v2/categories/tequila.svg"
                alt="Tequila"
              />
              <p>Eau de vie
                <span v-if="!returnDelay(1).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(1).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(1).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Cognac, Armagnac, Brandy, Grappa, Marc, Kirsh, Schnaps, Eau de vie, Pisco, Calvados, Rakia"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </el-tooltip>
            </div>
            <div class="cognac">
              <img src="@/assets/svg/v2/categories/cognac.svg" alt="Cognac" />
              <p>Agave, Distillate & Various
                <span v-if="!returnDelay(10).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(10).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(10).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Vodka, Gin, Genever, Sake, Shochu, Soju, Baiju, Mezcal, Tequila, Sotol, Bacanora, Various"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img" />
              </el-tooltip>
            </div>
            <div class="liquors">
              <img
                src="@/assets/svg/v2/categories/liquors.svg"
                alt="Liquors"
              />
              <p>Liqueur & Aperitif
                <span v-if="!returnDelay(9).isSub" class="with-delay">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(9).value }}
                </span>
                <span v-else class="already-sub">
                  <span style="color: white;"> - </span>
                  {{ returnDelay(9).value }}
                </span>
              </p>
              <el-tooltip
                class="tooltip"
                effect="dark"
                content="Chartreuse, Liqueur, Absinthe, Ouzo, Crème"
                placement="top"
              >
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </el-popover>

    <div class="statistics">
      <div
        class="statistic-container-premium"
        v-loading="statsLoading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
      >
        <div class="running-premium">
          <div class="statistic-header">
            <img src="@/assets/svg/v2/portfolio/running.svg" alt="Running" class="themed-img" />
            <h3>{{ $t('portfolio.running') }}</h3>
          </div>
          <div class="statistic-body-premium">
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.bottle_on_hold_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.bottle_on_hold') }}</p>
              </el-tooltip>
              <span>{{
                formatNumberWithSpaces(pfmStatistics?.on_hold) || '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.unique_references_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.unique_ticker') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.unique_references
                  ? formatNumberWithSpaces(pfmStatistics.unique_references)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.on_position_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.on_position') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.on_position
                  ? formatNumberWithSpaces(pfmStatistics.on_position)
                  : '-'
              }}</span>
            </div>
            <div
              :class="{ 'negative-number': parseFloat(pfmStatistics?.aum) < 0 }"
              class="row"
            >
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.AUM_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.AUM') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.aum
                  ? formatNumberWithSpaces(pfmStatistics.aum)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.running_pnl_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.running_pnl') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number': parseFloat(pfmStatistics?.running_pnl) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.running_pnl) || '-'
                }}</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.running_return_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.running_return') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number': parseFloat(pfmStatistics?.yield) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.yield) || '-'
                }}
                %</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.running_year_return_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.running_year_return') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number': parseFloat(pfmStatistics?.yield_year) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.yield_year) || '-'
                }}
                %</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.average_price_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.average_price') }}</p>
              </el-tooltip>
              <span>{{
                formatNumberWithSpaces(pfmStatistics?.avg_bought) || '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.average_holding_length_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.average_holding_length') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.average_holding_length
                  ? formatNumberWithSpaces(pfmStatistics.average_holding_length)
                  : '-'
              }}</span>
            </div>
          </div>
        </div>
        <div class="historical-premium">
          <div class="statistic-header">
            <img
              src="@/assets/svg/v2/portfolio/historical.svg"
              class="themed-img"
              alt="Historical"
            />
            <h3>{{ $t('portfolio.historical') }}</h3>
          </div>
          <div class="statistic-body-premium">
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.bought_bottles_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.bought_bottles') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.bought_bottles
                  ? formatNumberWithSpaces(pfmStatistics.bought_bottles)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.sold_bottles_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.sold_bottles') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.sold_bottles
                  ? formatNumberWithSpaces(pfmStatistics.sold_bottles)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.total_spent_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.total_spent') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.bought_total_invest
                  ? formatNumberWithSpaces(pfmStatistics.bought_total_invest)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.total_sold_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.total_sold') }}</p>
              </el-tooltip>
              <span>{{
                pfmStatistics && pfmStatistics.sold_total_invest
                  ? formatNumberWithSpaces(pfmStatistics.sold_total_invest)
                  : '-'
              }}</span>
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.total_pnl_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.total_pnl') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number': parseFloat(pfmStatistics?.total_pnl) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.total_pnl) || '-'
                }}</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.total_pnl_period_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.total_pnl_period') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number': parseFloat(pfmStatistics?.period_pnl) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.period_pnl) || '-'
                }}</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.realized_total_return_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.realized_total_return') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number':
                    parseFloat(pfmStatistics?.total_yield_history) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.total_yield_history) ||
                  '-'
                }}
                %</span
              >
            </div>
            <div class="row">
              <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('portfolio.realized_year_return_description')"
                placement="top"
              >
                <p>{{ $t('portfolio.realized_year_return') }}</p>
              </el-tooltip>
              <span
                :class="{
                  'negative-number':
                    parseFloat(pfmStatistics?.yield_year_realized) < 0,
                }"
                >{{
                  formatNumberWithSpaces(pfmStatistics?.yield_year_realized) ||
                  '-'
                }}
                %</span
              >
            </div>
          </div>
        </div>
        <div class="volatility-premium">
          <div class="statistic-header">
            <img
              src="@/assets/svg/v2/portfolio/volatility.svg"
              class="themed-img"
              alt="Volatility"
            />
            <h3>{{ $t('portfolio.volatility') }}</h3>
          </div>
          <div class="statistic-body-premium">
            <div class="row">
              <p>{{ $t('portfolio.volatility_portfolio') }}</p>
              <span>11 %</span>
            </div>
            <div class="row">
              <p>V@R</p>
              <span>111</span>
            </div>
            <div class="row">
              <p>{{ $t('portfolio.volatility_spirits_type') }}</p>
              <span>11.1 %</span>
            </div>
            <div class="row">
              <p>{{ $t('portfolio.volatility_distillery') }}</p>
              <span>11 %</span>
            </div>
            <div class="row">
              <p>{{ $t('portfolio.volatility_vintage') }}</p>
              <span>11 %</span>
            </div>
            <div class="row">
              <p>{{ $t('portfolio.volatility_age') }}</p>
              <span>11.1 %</span>
            </div>
          </div>
          <div class="portfolio-soon">
            <img src="@/assets/svg/v2/portfolio/lock.svg" alt="Historical" class="themed-img"/>
            <p>{{ $t('portfolio.portfolio_soon') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { find } from 'lodash';
import { inject } from "vue"

export default {
  name: 'statistics',
  data() {
    return {
      darkmode: inject("darkmode"),
      form: {
        currency: 'EUR',
        period: 'a',
      },
      statsLoading: true,
      isPremiumDisplay: false,
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
      pfmStatistics: 'pfmStatistics',
      pfmStatisticsCurrency: 'pfmStatisticsCurrency',
      pfmStatisticsPeriod: 'pfmStatisticsPeriod',
      userSubscriptions: 'getSubscriptions',
      pfmBottlesCategoryInPortfolio: 'pfmBottlesCategoryInPortfolio',
      registerStep: 'registerStep'
    }),
  },
  methods: {
    ...mapActions({
      getStatistics: 'getStatistics',
    }),
    async statistics() {
      this.statsLoading = true

      const payload = {
        currency: this.form.currency,
        period: this.form.period,
      }

      await this.getStatistics(payload).then(() => {
        this.statsLoading = false
      })
    },
    returnGlobalDelay() {
      let delay = 0;

      const findSubscriptionsCategory = find(this.userSubscriptions, (o) => {
        return this.pfmBottlesCategoryInPortfolio.includes(o.subscription_spirit_code) && o.subscription_state === 0; 
      })

      if (findSubscriptionsCategory) {
        
        if (this.registerStep < 3) {
          delay = 15;
        } else {
          delay = 12;
        }

      }
      
      return delay
    },
    returnDelay(spiritType) {
      let delay = {
        value : 0,
        isSub: false
      }

      const findSubscriptionsCategory = find(this.userSubscriptions, (o) => {
        return o.subscription_state === 1 && o.subscription_spirit_code === spiritType; 
      })

      if (!findSubscriptionsCategory) {
        
        if (this.registerStep < 3) {
          delay.value = 15 + ` ${this.$t('product_page.price_history.delay_2')}`;
          return delay;
        } else {
          delay.value = 12 + ` ${this.$t('product_page.price_history.delay_2')}`;
          return delay;
        }
      } else {
        delay.value = this.$t('subscriptions.already_sub_short');
        delay.isSub = true;
      }

      return delay;
      
    },
    formatNumberWithSpaces(number) {
      if (!number) return null
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
  mounted() {
    this.statistics()
  },
  watch: {
    'form.currency'() {
      this.$store.commit('SET_STATISTICS_CURRENCY', this.form.currency)
      this.statistics()
    },
    'form.period'() {
      this.$store.commit('SET_STATISTICS_PERIOD', this.form.period)
      this.statistics()
    },
    pfmStatisticsCurrency(val) {
      this.form.currency = val
    },
    pfmStatisticsPeriod(val) {
      this.form.period = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root';

.statistics-header,
.row-header,
.premium-display {
  display: flex;
}

.select {
  margin-right: 20px;
  width: 100px;
}

.statistics-container,
.statistic-container-premium {
  margin-bottom: 10px;

  h2 {
    margin-right: 20px;
  }
}

.statistics-header {
  justify-content: space-between;

  .premium-display {
    color: var(--text-color);
    align-items: center;

    p {
      margin-right: 8px;
    }
  }

  .row-header {
    margin-bottom: 15px;
  }
}

.page-subtitle {
  color: var(--text-color);
  font-size: 24px;
}

.statistics {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.spirits-types-list {

  .spirits-types-list-1,
  .spirits-types-list-2 {
    z-index: 5;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      margin-top: 5px;

      p {
        margin-left: 5px;
        margin-right: 5px;
        color: var(--text-color);
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .spirits-types-list-1 {
    margin-right: 20px;
  }
}

.portfolio-stats-history-delay {
  display: flex;
  color: var(--delay-color);
  align-items: center;
  cursor: pointer;
  
  span {
    color: var(--text-color);
    margin-left: 3px;
    margin-right: 8px;
  }
  
  p {
    border-bottom: 1px dashed;
  }
}

.with-delay {
  color: var(--delay-color);
}

.already-sub {
  color: var(--buysuccess-color);
}

.running-premium,
.historical-premium,
.volatility-premium {
  width: 30%;
  padding: 0 10px;
}

.historical-premium,
.volatility-premium {
  padding: 0 20px;
}

.historical-premium,
.volatility-premium {
  border-left: 1px solid var(--border-grey);
}

.volatility-premium {
  position: relative;

  .statistic-header,
  .statistic-body-premium {
    filter: blur(5px);
  }

  .portfolio-soon {
    position: absolute;
    color: var(--text-color);
    top: 40%;
    width: 100%;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
  }
}

.statistic-container,
.statistic-container-premium {
  padding: 20px;
  border: 1px solid var(--border);
  border-radius: 12px;
  flex-grow: 1;
  background: var(--bg-stat-gradient);
  .statistic-body,
  .statistic-body-premium {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .statistic-body-premium {
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0px;

    p {
      font-size: 16px;
      color: var(--text-color);
    }

    span {
      color: var(--text-color);
      font-size: 16px;
    }
  }

  .column {
    flex: 1;
  }

  .column:first-child {
    padding-right: 20px;
    border-right: 1px solid var(--border);
  }

  .column:last-child {
    padding-left: 20px;
  }
}

.statistic-container-premium {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statistic-header {
  display: flex;
  flex-direction: row;
  color: var(--text-color);
  align-items: center;

  h3 {
    margin-left: 10px;
  }
}

.negative-number {
  color: $alert-2-color !important;
}

.hidden-tel {
  display: flex;
}

.hidden-pc {
  display: none;
}

@media screen and (max-width: 500px) {
  .hidden-tel {
    display: none;
  }

  .hidden-pc {
    display: flex;
  }

  .portfolio-stats-history-delay {
    margin-bottom: 10px;

    span, p {
      font-size: 12px;
    }
  }

  .statistics,
  .statistic-container-premium {
    flex-direction: column;
    gap: 10px;
  }

  .statistics-header .row-header {
    width: 100%;
    margin: auto inherit;
    justify-content: space-between;
  }
  .statistic-container,
  .statistic-container-premium {
    padding: 0;
    border: none;
    border-radius: none;
    flex-grow: 1;
    background: none;
  }

  .running-premium,
  .historical-premium,
  .volatility-premium {
    width: 90%;
    margin: auto;
    padding: 20px;
    border: 1px solid var(--border);
    border-radius: 12px;
    background: linear-gradient(
        180deg,
        rgba(38, 103, 255, 0.16) 0%,
        rgba(38, 103, 255, 0) 23.99%
      ),
      rgba(38, 103, 255, 0);
  }

  .portfolio-soon {
    top: 50%;
    left: 0;
  }
}

@media screen and (max-width: 380px) {
  .running-premium,
  .historical-premium,
  .volatility-premium {
    padding: 10px 20px;
  }

  .statistic-body-premium:nth-child(1) {
    margin-top: 5px;
    padding-top: 5px;
  }
}
</style>
